import axios from 'axios';
import config from '../config';
import { Session } from 'bc-react-session';
import { browserName, osName, osVersion } from 'react-device-detect';

const session = Session.getSession();
let headers = {};

if (session.isValid) {
  headers = {
    'Authorization': `Bearer ${session.payload.token}`,
    'Content-Type': 'application/json',
  }
}

class ResourceProvider {
  static async add(data) {
    data.metadata.device = {
      browser: browserName,
      os_name: osName,
      os_version: osVersion,
    };
    return await axios({
      mode: 'no-cors',
      method: 'POST',
      url: `${config.api.baseurl}resources`,
      crossdomain: true,
      headers,
      data,
    })
    .then((json) => json.data)
    .then((res) => {
      return res;
    });
  }

  static async getTestingSites() {
    return await axios({
      mode: 'no-cors',
      method: 'GET',
      url: `${config.api.baseurl}resources?name=Testing Sites`,
      crossdomain: true,
      headers,
    })
    .then((json) => json.data)
    .then((res) => {
      return res.data;
    });
  }
}

export default ResourceProvider;
