import React, { useState, useEffect } from 'react';
import moment from 'moment';
import CSVReader from 'react-csv-reader';
import { Container, Col, Row } from 'reactstrap';
import { HashLinkContainer } from 'components';
import { CaseProvider, CountriesProvider } from 'providers';

const BulkCapture = () => {
    const [csvData, setCsvData] = useState(null);
    const [fileData, setFileData] = useState(null);
    const [activeTab, setActiveTab] = useState(1);
    const [fileInputLabel, setFileInputLabel] = useState('Choose file');
    const [fileSelected, setFileSelected] = useState(false);
    const [firstRowIsHeader, setFirstRowIsHeader] = useState(true);
    const [caseTypes, setCaseTypes] = useState([]);
    const [caseType, setCaseType] = useState('');
    const [caseTypeDescr, setCaseTypeDescr] = useState('');
    const [description, setDescription] = useState('provinces');
    const [rel, setRel] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const provinces = await CountriesProvider.getProvinces();
            const types = await CaseProvider.getTypes();
            setCaseTypes(types);
            setRel(provinces);
        };
        fetchData();
    }, []);

    const toggleTab = (e, key) => {
        e.preventDefault();
        let aTab = activeTab;
        if (key === 'next') {
            aTab += 1;
            setActiveTab(aTab);
        } else {
            if (aTab > 1) {
                aTab -= 1;
                setActiveTab(aTab);
            }
        }
    };

    const handleChooseFile = (value) => {
        if (value.length > 0) {
            setFileSelected(true);
            setFileInputLabel(value);
        } else {
            setFileSelected(false);
            setFileInputLabel('Choose file');
        }
    }
    
    const handleImport = async (e) => {
        e.preventDefault();
        const cases = csvData.filter((item, index) => {
            if (firstRowIsHeader && index === 0) {
                return false;
            } else {
                return item;
            }
        });
        const data = { cases, case_type: caseType, description, rel };
        const result = await CaseProvider.addBulk(data);
        if (result.success) {
            alert('Imported data successfully!');
            window.location = '/cpanel/dashboard';
        } else {
            alert(result.message ? result.message : 'An error has occured, please try again!');
            window.location = '/cpanel/cases/bulk-capture';
        }
    }

    return (
        <Container fluid className="pd-x-0 pd-lg-x-10 pd-xl-x-0">
            <ol className="breadcrumb df-breadcrumbs mg-b-10" style={{ padding: 0, backgroundColor: 'transparent' }}>
                <li className="breadcrumb-item">
                    <HashLinkContainer to="/cpanel/dashboard">
                        <a href="/cpanel/dashboard">Dashboard</a>
                    </HashLinkContainer>
                </li>
                <li className="breadcrumb-item">Cases</li>
                <li className="breadcrumb-item active" aria-current="page">Bulk Capture</li>
            </ol>
            <div className="d-sm-flex align-items-center justify-content-between">
                <div>
                    <h4 className="mg-b-10 mg-t-30">Bulk Case Capture</h4>
                    <p className="mg-b-30 tx-color-03">Captured on {moment().format('MMMM DD, YYYY')}</p>
                </div>
            </div>
            <div className="tx-13 mg-b-25">
                <form encType="multipart/form-data">
                    <div className="wizard clearfix">
                        <div className="steps clearfix">
                            <ul role="tablist">
                                <li
                                    role="tab"
                                    className={`first ${activeTab === 1 ? 'current' : ''} ${activeTab > 1 ? 'done' : ''}`}
                                    aria-disabled="false"
                                    aria-selected={activeTab === 1}
                                >
                                    <a role="button" id="import-t-0" aria-controls="wizard2-p-0">
                                        <span className="current-info audible">current step: </span>
                                        <span className="number">1</span>
                                        <span className="title">File Upload</span>
                                    </a>
                                </li>
                                <li
                                    role="tab"
                                    className={`${activeTab < 2 ? 'disabled' : ''}  ${activeTab === 2 ? 'current' : ''}  ${activeTab > 2 ? 'done' : ''}`}
                                    aria-disabled={activeTab < 2}
                                    aria-selected={activeTab === 2}
                                >
                                    <a role="button" id="import-t-1" aria-controls="wizard2-p-1">
                                        <span className="number">2</span>
                                        <span className="title">Import Settings </span>
                                    </a>
                                </li>
                                <li
                                    role="tab"
                                    className={`last ${activeTab < 3 ? 'disabled' : ''}  ${activeTab === 3 ? 'current' : ''}`}
                                    aria-disabled={activeTab < 3}
                                    aria-selected={activeTab === 3}
                                >
                                    <a role="button" id="import-t-2" aria-controls="wizard2-p-2">
                                        <span className="number">3</span> <span className="title">Import Data</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="content clearfix">
                            {activeTab === 1
                            && (
                            <div>
                                <h3 id="wizard2-h-0" tabIndex="-1" className={`title ${activeTab === 1 ? 'current' : ''}`}>Select File to Import</h3>
                                <section id="wizard2-p-0" role="tabpanel" aria-labelledby="wizard2-h-0" className={`body ${activeTab === 1 ? 'current' : ''}`} aria-hidden="false">
                                    <p className="mg-b-20">
                                        Table Data Import allows you to easily import CSV, JSON datafiles.
                                    </p>
                                    <Row className="row-sm pd-y-30">
                                        <Col md={4}>
                                            <Row>
                                                <Col md={12}>
                                                    <label className="form-control-label" htmlFor="case_type">Case Type:</label>
                                                </Col>
                                                <Col md={12} className="mg-t-20 mg-md-t-0">
                                                    <select
                                                        id="case_type"
                                                        name="case_type"
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            setCaseType(e.target.value);
                                                            if (e.target.value.length > 0) {
                                                                const type = caseTypes.filter(item => e.target.value === item.id);
                                                                setCaseTypeDescr(type.length > 0 ? type[0].description : '');
                                                            }
                                                            return true;
                                                        }}
                                                    >
                                                        <option>Select Case Type</option>
                                                        {caseTypes.length > 0
                                                        && caseTypes.map(item => <option key={item.id} value={item.id}>{item.description}</option>)}
                                                    </select>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={4}>
                                            <Row>
                                                <Col md={12}>
                                                    <label className="form-control-label" htmlFor="descr">Cases For:</label>
                                                </Col>
                                                <Col md={12} className="mg-t-20 mg-md-t-0">
                                                    <select
                                                        id="descr"
                                                        name="descr"
                                                        className="form-control"
                                                        defaultValue="provinces"
                                                        onChange={(e) => {
                                                            setDescription(e.target.value);
                                                        }}
                                                    >
                                                        <option value="districts">District</option>
                                                        <option value="provinces">Province</option>
                                                        <option value="countries">Country</option>
                                                    </select>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={4}>
                                            <Row>
                                                <Col md={12}>
                                                    <label className="form-control-label" htmlFor="file">File Path:</label>
                                                </Col>
                                                <Col md={12} className="mg-t-20 mg-md-t-0">
                                                    <CSVReader
                                                        accept=".csv, text/csv"
                                                        cssClass="custom-file"
                                                        cssInputClass="custom-file-input"
                                                        inputId="file"
                                                        label={<label className="custom-file-label" htmlFor="file">{fileInputLabel}</label>}
                                                        onFileLoaded={(data, fileInfo) => {
                                                            setCsvData(data);
                                                            setFileData(fileInfo);
                                                            handleChooseFile(fileInfo.name + ` (${fileInfo.size > 1024 ? `${parseInt(fileInfo.size / 1024)}KB` : `${fileInfo.size}B`})`);
                                                        }}
                                                        onError={() => {
                                                            alert('Error: Invalid Input!');
                                                        }}
                                                    />
                                                    <div className="hint-text text-muted pd-t-5">
                                                        Supported file format is CSV
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </section>
                            </div>)}
                            {activeTab === 2
                            && (
                            <div>
                                <h3 id="wizard2-h-1" tabIndex="-1" className={`title ${activeTab === 2 ? 'current' : ''}`}>Configure Import Settings</h3>
                                <section id="wizard2-p-1" role="tabpanel" aria-labelledby="wizard2-h-1" className={`body ${activeTab === 2 ? 'current' : ''}`} aria-hidden="true">
                                    <p className="mg-b-5">Case type: {caseTypeDescr}</p>
                                    <p>Detected file format: {fileData !== null && fileData.name.substr(fileData.name.indexOf('.') + 1).toUpperCase()}</p>
                                    <div className="bd pd-20 mg-t-20">
                                        <h6>Options</h6>
                                        <div className="custom-control custom-checkbox mg-t-10">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="firstRowIsHeader"
                                                checked={firstRowIsHeader}
                                                onChange={(e) => {
                                                    setFirstRowIsHeader(e.target.checked);
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="firstRowIsHeader">First row is header</label>
                                        </div>
                                    </div>
                                    <div className="mg-y-20">
                                        <h6>Data to be imported</h6>
                                        {csvData !== null
                                        && (
                                        <div className="table-responsive" style={{ maxHeight: 300, overflowY: 'auto' }}>
                                            <table className="table table-bordered">
                                                <tbody>
                                                    {csvData.length > 0
                                                    && csvData.map((arr, i) => (
                                                        <tr key={i.toString()}>
                                                            {arr.map((item, index) => {
                                                                return (
                                                                    <td key={(index + 300000).toString()}>
                                                                        {(firstRowIsHeader && i === 0)
                                                                        ? <strong>{item}</strong>
                                                                        : <span>{item}</span>}
                                                                    </td>
                                                                );
                                                            })}
                                                        </tr> 
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>)}
                                    </div>
                                </section>
                            </div>)}
                            {activeTab === 3
                            && (
                            <div>
                                <h3 id="wizard2-h-2" tabIndex="-1" className={`title ${activeTab === 3 ? 'current' : ''}`}>Import Cases</h3>
                                <section id="wizard2-p-2" role="tabpanel" aria-labelledby="wizard2-h-2" className={`body ${activeTab === 3 ? 'current' : ''}`} aria-hidden="true">
                                    <p>The following tasks will now be performed.</p>
                                    <ul>
                                        <li>Prepare Import</li>
                                        <li>Import data file</li>
                                    </ul>
                                    <p>Click [Finish] to execute.</p>
                                </section>
                            </div>)}
                        </div>
                        <div className="actions clearfix">
                            <ul role="menu" aria-label="Pagination">
                                <li
                                    className={activeTab === 1 ? 'disabled' : ''}
                                    aria-disabled={activeTab === 1}
                                >
                                    <a href="/" role="menuitem" onClick={(e) => toggleTab(e, 'prev')}>Previous</a>
                                </li>
                                {activeTab < 3
                                && (
                                <li
                                    className={fileSelected ? '' : 'disabled'}
                                    aria-disabled={!fileSelected}
                                    aria-hidden="false"
                                >
                                    <a href="/" role="menuitem" onClick={(e) => toggleTab(e, 'next')}>Next</a>
                                </li>)}
                                {activeTab < 3
                                ? <li style={{ display: 'none' }}><a href="/" role="menuitem">Finish</a></li>
                                : <li><a href="/" role="menuitem" onClick={(e) => handleImport(e)}>Finish</a></li>}
                            </ul>
                        </div>
                    </div>
                </form>
            </div>
        </Container>
    );
};

export default BulkCapture;
