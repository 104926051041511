import React, { useState } from 'react';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { BarChart } from 'components';
import { GoogleSheetsProvider } from 'providers';

const chartOptions = {
    scales: {
        xAxes: [{ stacked: true }],
        yAxes: [{ stacked: true }],
    },
};

const InfectionsVsRecoveries = props => {
    const { view, loaded, query, province } = props;
    const [infections, setInfections] = useState(0);
    const [recoveries, setRecoveries] = useState(0);
    const [chartData, setChartData] = useState({});
    const [componentLoaded, setComponentLoaded] = useState(false);

    const fetchData = async () => {
        if (loaded) {
            let infections = 0;
            let recoveries = 0;
            const labels = [];
            const confirmedData = [];
            const recoveredData = [];
            const data = await GoogleSheetsProvider.cases();

            if (data.length > 0) {
                infections = data[data.length - 1].confirmed;
                recoveries = data[data.length - 1].recovered;
            }

            data.map(item => {
                labels.push(moment(item.date).format('DD.MM'));
                confirmedData.push(item.confirmed);
                recoveredData.push(item.recovered);
                return true;
            });
            const chartData = {
                labels,
                datasets: [
                {
                    label: 'Active Infections',
                    backgroundColor: 'rgba(253,126,20,0.8)',
                    data: confirmedData,
                },
                {
                    label: 'Recovered',
                    data: recoveredData,
                }],
            };
            setInfections(infections);
            setRecoveries(recoveries);
            setChartData(chartData);
            setComponentLoaded(true);
        }
    };

    if (loaded) {
        if (!componentLoaded) {
            fetchData();
        }
    }

    return (
        <Card>
            <div className="card-header pd-t-20 pd-b-0 bd-b-0">
                <h5 className="mg-b-5">Infections vs Recoveries</h5>
                <p className="tx-12 tx-color-03 mg-b-0">Number of active infections and number of recovered</p>
            </div>
            <CardBody className="pd-20">
                <div className="mg-b-20">
                    <BarChart chartData={chartData} options={chartOptions} />
                </div>
                <Row>
                    <Col xs={6} className="mg-t-20 mg-sm-t-0">
                        <h4 className="tx-normal tx-rubik tx-spacing--1 mg-b-5">
                            <NumberFormat displayType={'text'} thousandSeparator value={infections} />
                        </h4>
                        <p className="tx-11 tx-uppercase tx-spacing-1 tx-semibold mg-b-10 tx-orange">Active infections</p>
                        <div className="tx-12 tx-color-03">People that have tested positive to date.</div>
                    </Col>
                    <Col xs={6}>
                        <h4 className="tx-normal tx-rubik tx-spacing--1 mg-b-5">
                            <NumberFormat displayType={'text'} thousandSeparator value={recoveries} />
                        </h4>
                        <p className="tx-11 tx-uppercase tx-spacing-1 tx-semibold mg-b-10 tx-primary">Recovered</p>
                        <div className="tx-12 tx-color-03">People that have recovered who previously tested positive.</div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

export default InfectionsVsRecoveries;
