import React from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const GoogleMap = props => {
    return (
        <div style={{ height: '50vh', width: '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyCzkxzbEni5vR_Ugt1De8gBzrLX3236bnA' }}
                defaultCenter={{
                    lat: 59.95,
                    lng: 30.33,
                }}
                defaultZoom={11}
                yesIWantToUseGoogleMapApiInternals
                >
                <AnyReactComponent
                    lat={59.955413}
                    lng={30.337844}
                    text="V"
                />
                </GoogleMapReact>
        </div>
    );
};

export default GoogleMap;
