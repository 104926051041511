import React from 'react';
import NumberFormat from 'react-number-format';

const TableRow = props => {
    const {
        name,
        all_bed_avail,
        icu_bed_avail,
        all_bed_short,
        iso_code,
        test_sites,
    } = props;

    return (
        <tr>
            <td><strong>{name}</strong></td>
            <td className="tx-color-03">{iso_code}</td>
            <td className="tx-medium text-right tx-primary">
                <NumberFormat displayType={'text'} thousandSeparator value={all_bed_avail} />
            </td>
            <td className="tx-medium text-right tx-pink">
                <NumberFormat displayType={'text'} thousandSeparator value={all_bed_short} />
            </td>
            <td className="tx-medium text-right tx-orange">
                <NumberFormat displayType={'text'} thousandSeparator value={icu_bed_avail} />
            </td>
            <td className="tx-medium text-right tx-teal">
                <NumberFormat displayType={'text'} thousandSeparator value={test_sites} />
            </td>
        </tr>
    );
};

const ResourceUseProvinces = props => {
    const { tableData } = props;
    return (
        <div className="table-responsive">
            {tableData.length > 0
            && (<table className="table table-dashboard mg-b-0">
                <thead>
                    <tr>
                        <th>Province</th>
                        <th>ISO Code</th>
                        <th className="text-right">All Beds</th>
                        <th className="text-right">Bed Shortages</th>
                        <th className="text-right">ICU Beds</th>
                        <th className="text-right">Testing Sites</th>
                    </tr>
                </thead>
                <tbody>
                    {tableData.sort((a, b) => b.all_bed_avail - a.all_bed_avail).map((data) => <TableRow key={data.iso_code} {...data} />)}
                </tbody>
            </table>)}
        </div>
    );
};

export default ResourceUseProvinces;
