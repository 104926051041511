import React, { useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { DoughnutChart } from 'components';

const OriginOfInfectionPie = props => {
    const { view, loaded, query, province } = props;
    const [chartData, setChartData] = useState({});

    const cdata = {
        labels: [
            'Domestic',
            'Foreign',
            'Unknown',
        ],
        datasets: [{
            data: [1234, 736, 27],
            backgroundColor: ['rgba(1,104,250,0.7)','rgba(253,126,20,0.7)'],
        }],
    };

    return (
        <Card>
            <CardBody className="pd-20">
                <h6 className="mg-b-5">Origin of infection</h6>
                <div className="pd-y-30">
                    <DoughnutChart chartData={cdata} />
                </div>
            </CardBody>
        </Card>
    );
};

export default OriginOfInfectionPie;
