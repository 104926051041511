import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { GoogleMap } from 'components';

const InfectionsQuarantineByRegion = props => {
    return (
        <Card className="ht-lg-100p">
            <div className="card-header d-flex align-items-center justify-content-between">
                <h6 className="mg-b-0">Infections / Quarantine, by region</h6>
            </div>
            <CardBody className="pd-0">
                <div className="pd-20">
                    <GoogleMap />
                </div>
                {/* <div className="table-responsive">
                    <table className="table table-borderless table-dashboard table-dashboard-one">
                        <thead>
                            <tr>
                                <th className="wd-40">&nbsp;</th>
                                <th className="wd-25 text-right">Infections</th>
                                <th className="wd-35 text-right">Quarantine</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="tx-medium">Gauteng</td>
                                <td className="text-right">12,201</td>
                                <td className="text-right">1,200</td>
                            </tr>
                            <tr>
                                <td className="tx-medium">Western Cape</td>
                                <td className="text-right">11,950</td>
                                <td className="text-right">8,910</td>
                            </tr>
                            <tr>
                                <td className="tx-medium">KwaZulu Natal</td>
                                <td className="text-right">11,198</td>
                                <td className="text-right">2,050</td>
                            </tr>
                            <tr>
                                <td className="tx-medium">Eastern Cape</td>
                                <td className="text-right">9,885</td>
                                <td className="text-right">7,762</td>
                            </tr>
                            <tr>
                                <td className="tx-medium">North West</td>
                                <td className="text-right">8,560</td>
                                <td className="text-right">7,087</td>
                            </tr>
                        </tbody>
                    </table>
                </div> */}
            </CardBody>
        </Card>
    );
};

export default InfectionsQuarantineByRegion;
