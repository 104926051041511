import axios from 'axios';
import config from '../config';
// import { Session } from 'bc-react-session';
import SessionProvider from './SessionProvider';

const session = SessionProvider.get();
let headers = {};

if (session.isValid) {
  headers = {
    'Authorization': `Bearer ${session.payload.token}`,
  }
}

class UserProvider {
  static async count() {
    return await axios({
      mode: 'no-cors',
      method: 'GET',
      headers: headers,
      url: `${config.api.baseurl}users/count`,
      crossdomain: true,
    })
    .then((json) => json.data)
    .then((res) => {
      return res.data;
    });
  }
}

export default UserProvider;
