import React, { useState } from 'react';
import moment from 'moment';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { BarChart } from 'components';
import { GoogleSheetsProvider } from 'providers';

const chartOptions = {
    scales: {
        xAxes: [{ stacked: true }],
        yAxes: [{ stacked: true }],
    },
};

const InfectedPersons = props => {
    const { view, loaded, query, province } = props;
    const [chartData, setChartData] = useState({});
    const [componentLoaded, setComponentLoaded] = useState(false);

    const fetchData = async () => {
        if (loaded) {
            const labels = [];
            const confirmedData = [];
            const data = await GoogleSheetsProvider.cases();
            data.map(item => {
                labels.push(moment(item.date).format('DD.MM'));
                confirmedData.push(item.confirmed);
                return true;
            });
            const chartData = {
                labels,
                datasets: [
                {
                    label: 'Infections',
                    backgroundColor: 'rgba(1,104,250,0.7)',
                    data: confirmedData,
                }],
            };
            setChartData(chartData);
            setComponentLoaded(true);
        }
    };

    if (loaded) {
        if (!componentLoaded) {
            fetchData();
        }
    }

    return (
        <Card className="mg-b-10">
            <div className="card-header pd-t-20 d-sm-flex align-items-start justify-content-between bd-b-0 pd-b-0">
                <div>
                    <h5 className="mg-b-5">Infected Persons</h5>
                    <p className="tx-13 tx-color-03 mg-b-0">Number of infected persons, by date</p>
                </div>
            </div>
            <CardBody className="pd-20">
                <div className="mg-b-20">
                    <BarChart chartData={chartData} options={chartOptions} />
                </div>
                <div className="media pd-y-10">
                    <span className="tx-color-04"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-download wd-60 ht-60"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg></span>
                    <div className="media-body mg-l-20">
                        <h6 className="mg-b-10">Download stats in CSV format.</h6>
                        <p className="tx-color-03 mg-b-0">
                            When information is published on the number of new infections
                            since the previous day, it is possible that the column in the
                            bar graph will not be the same. This is because the
                            publication of information on new infections is based on the
                            date the test was taken and not the date the infection was
                            diagnosed.
                        </p>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};

export default InfectedPersons;
