import React, { useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { BarChart } from 'components';

const InfectionsRecoveredDeathsByAge = props => {
    const { view, loaded, query, province } = props;
    const [chartData, setChartData] = useState({});

    const cdata = {
        datasets: [
        {
            label: 'Active Infections',
            backgroundColor: 'rgba(1,104,250,0.7)',
            data: [0,0,4,10,15,29,37,47,54,59,68,82,106]
        },
        {
            label: 'Recovered',
            data: [0,0,0,0,1,2,5,10,13,22,23,35,12]
        }],
        labels: ['<1','1-5','6-12','13-17','18-29','30-39','40-49','50-59','60-69','70-79','80-89','90-99','100+']
    };

    const chartOptions = {
        scales: {
            xAxes: [{
                stacked: true
            }],
            yAxes: [{
                stacked: true
            }]
        }
    };

    return (
        <Card className="ht-lg-100p">
            <div className="card-header d-flex align-items-center justify-content-between">
                <h6 className="mg-b-0">Number of active infections, recovered and deaths by age</h6>
            </div>
            <CardBody>
                <div className="pd-y-30">
                    <BarChart chartData={cdata} options={chartOptions} />
                </div>
            </CardBody>
        </Card>
    );
};

export default InfectionsRecoveredDeathsByAge;
