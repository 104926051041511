import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
// import { Session } from 'bc-react-session';
import { HashLinkContainer } from 'components';
import { UsersProvider, SessionProvider } from 'providers';

const session = SessionProvider.get();
let user = {};
let role = {};

if (session.isValid) {
    user = session.payload.user;
    role = user.user_role;
}

const Dashboard = () => {
    const [count, setCount] = useState({});
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const count = await UsersProvider.count();
            setCount(count);
            setDataLoaded(true);
        };
        if (role.name) {
            if (role.name.toLowerCase() === 'admin') {
                fetchData();
            }
        }
    }, [setCount]);

    return (
        <Container fluid className="pd-x-0 pd-lg-x-10 pd-xl-x-0">
            <Row className="row-xs">
                <Col lg={12} className="mg-b-10">
                    <Card className="mg-b-10">
                        <CardBody>
                            <div className="media">
                                <div className="media-body">
                                    <h4 className="mg-b-10">Welcome back, {user.first_name}</h4>
                                    <p className="tx-color-03 mg-b-0">
                                        In locations without social distancing measures currently in place, we have assumed they will be in place within seven days of the last model update. If not, the number of deaths and burden on their hospital systems will likely be higher than the model predicts.
										For answers to other frequently asked questions about the modeling.
										To view the paper with in-depth analysis and a description of the methods used to produce the projections.
									</p>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {/* {dataLoaded
            && (
            <Row className="row-xs">
                <Col sm={6} lg={3} className="mg-b-10">
                    <div className="card card-body">
                        <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Users</h6>
                        <div className="d-flex d-lg-block d-xl-flex align-items-end">
                            <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{count.total_users}</h3>
                            <p className="tx-11 tx-color-03 mg-b-0">
                                <span className="tx-medium tx-success">
                                    1.2% <i className="icon ion-md-arrow-up"></i>
                                </span> than last week
                            </p>
                        </div>
                    </div>
                </Col>
                <Col sm={6} lg={3} className="mg-b-10 mg-sm-b-0">
                    <div className="card card-body">
                        <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Total users</h6>
                        <div className="d-flex d-lg-block d-xl-flex align-items-end">
                            <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{count.total_users}</h3>
                            <p className="tx-11 tx-color-03 mg-b-0">
                                <span className="tx-medium tx-success">
                                    1.2% <i className="icon ion-md-arrow-up"></i>
                                </span> than last week
                            </p>
                        </div>
                    </div>
                </Col>
                <Col sm={6} lg={3} className="mg-b-10 mg-sm-b-0">
                    <div className="card card-body">
                        <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Total users</h6>
                        <div className="d-flex d-lg-block d-xl-flex align-items-end">
                            <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{count.total_users}</h3>
                            <p className="tx-11 tx-color-03 mg-b-0">
                                <span className="tx-medium tx-success">
                                    1.2% <i className="icon ion-md-arrow-up"></i>
                                </span> than last week
                            </p>
                        </div>
                    </div>
                </Col>
                <Col sm={6} lg={3} className="mg-b-10 mg-sm-b-0">
                    <div className="card card-body">
                        <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">Total users</h6>
                        <div className="d-flex d-lg-block d-xl-flex align-items-end">
                            <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">{count.total_users}</h3>
                            <p className="tx-11 tx-color-03 mg-b-0">
                                <span className="tx-medium tx-success">
                                    1.2% <i className="icon ion-md-arrow-up"></i>
                                </span> than last week
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>)} */}
            <Row className="row-xs mg-t-10 mg-b-10">
                <Col xs={12} sm={3}>
                    <HashLinkContainer to="/cpanel/cases/capture">
                        <button className="btn btn-primary btn-lg btn-block btn-uppercase pd-y-30 display-2">
                            <span style={{ fontSize: '1.2em', fontWeight: 300 }}>Single Case Capture</span>
                        </button>
                    </HashLinkContainer>
                </Col>
                <Col xs={12} sm={3}>
                    <HashLinkContainer to="/cpanel/cases/bulk-capture">
                        <button className="btn btn-secondary btn-lg btn-block btn-uppercase pd-y-30 display-2">
                            <span style={{ fontSize: '1.2em', fontWeight: 300 }}>Bulk Case Import</span>
                        </button>
                    </HashLinkContainer>
                </Col>
                <Col xs={12} sm={3}>
                    <HashLinkContainer to="/cpanel/resources/capture">
                        <button className="btn btn-primary btn-lg btn-block btn-uppercase pd-y-30 display-2">
                            <span style={{ fontSize: '1.2em', fontWeight: 300 }}>Single Resource Record</span>
                        </button>
                    </HashLinkContainer>
                </Col>
                <Col xs={12} sm={3}>
                    <HashLinkContainer to="/cpanel/resources/bulk-capture">
                        <button className="btn btn-secondary btn-lg btn-block btn-uppercase pd-y-30 display-2">
                            <span style={{ fontSize: '1.2em', fontWeight: 300 }}>Bulk Resource Import</span>
                        </button>
                    </HashLinkContainer>
                </Col>
            </Row>
        </Container>
    );
};

export default Dashboard;
