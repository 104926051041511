import React, { useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { GoogleMap } from 'components';
import { ResourceProvider } from 'providers';

const TableRow = (props) => {
    const { facility } = props;
    return (
        <tr>
            <td className="tx-medium">{facility.name}</td>
            {/* <td>{facility.facility_type.label}</td> */}
            <td>{facility.subdistrict.district.name}</td>
            <td>{facility.subdistrict.district.province.name}</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
        </tr>
    );
};

const TestingSites = props => {
    const { loaded } = props;
    const [testingSites, setTestingSites] = useState([]);
    const [componentLoaded, setComponentLoaded] = useState(false);

    const fetchData = async () => {
        if (loaded) {
            const resources = await ResourceProvider.getTestingSites();
            if (resources.results) {
                setTestingSites(resources.results);
            }
            setComponentLoaded(true);
        }
    };

    if (loaded) {
        if (!componentLoaded) {
            fetchData();
        }
    }

    return (
        <React.Fragment>
            {componentLoaded
            && (
            <Card className="ht-lg-100p">
                <div className="card-header d-flex align-items-center justify-content-between">
                    <h5 className="mg-b-0">Testing Sites</h5>
                    <div className="tx-13 d-flex align-items-center">
                        <span className="mg-r-5">Facility Type:</span> <a href="" className="d-flex align-items-center link-03 lh-0">Clinic <i className="icon ion-ios-arrow-down mg-l-5"></i></a>
                    </div>
                </div>
                <CardBody className="pd-0">
                    <div className="pd-20">
                        <GoogleMap />
                    </div>
                    <div className="table-responsive">
                        <table className="table table-borderless table-dashboard table-dashboard-one">
                            <thead>
                            <tr>
                                <th className="wd-40">Name</th>
                                {/* <th className="wd-25">Type</th> */}
                                <th className="wd-35">District</th>
                                <th className="wd-35">Province</th>
                                <th className="wd-35">Contact</th>
                                <th className="wd-35">Lat</th>
                                <th className="wd-35">Long</th>
                            </tr>
                            </thead>
                            <tbody>
                                {testingSites.map(item => <TableRow key={item.id} {...item} />)}
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>)}
        </React.Fragment>
    );
};

export default TestingSites;
