import React, { useState } from 'react';
import moment from 'moment';
import { Card, CardBody } from 'reactstrap';
import { BarChart } from 'components';
import { GoogleSheetsProvider } from 'providers';

const chartOptions = {
    scales: {
        xAxes: [{ stacked: true }],
        yAxes: [{ stacked: true }],
    },
};

const NumberOfTests = props => {
    const { loaded, query } = props;
    const [chartData, setChartData] = useState({});
    const [componentLoaded, setComponentLoaded] = useState(false);

    const fetchData = async () => {
        if (loaded) {
            const labels = [];
            const cumulativeTestsData = [];
            const data = await GoogleSheetsProvider.cases();
            data.map(item => {
                labels.push(moment(item.date).format('DD.MM'));
                cumulativeTestsData.push(item.cumulative_tests);
                return true;
            });
            const chartData = {
                labels,
                datasets: [
                {
                    label: 'Tests',
                    backgroundColor: 'rgba(1,104,250,0.7)',
                    data: cumulativeTestsData,
                }],
            };
            setChartData(chartData);
            setComponentLoaded(true);
        }
    };

    if (loaded) {
        if (!componentLoaded) {
            fetchData();
        }
    }

    return (
        <Card>
            <div className="card-header pd-t-20 d-sm-flex align-items-start justify-content-between bd-b-0 pd-b-0">
                <div>
                    <h5 className="mg-b-5">Total Tests Performed</h5>
                    <p className="tx-13 tx-color-03 mg-b-0">Number of tests performed each day</p>
                </div>
            </div>
            <CardBody className="pd-20">
                <div>
                    <BarChart chartData={chartData} options={chartOptions} />
                </div>
            </CardBody>
        </Card>
    );
};

export default NumberOfTests;
