import axios from 'axios';
import config from '../config';
// import { Session } from 'bc-react-session';
import { browserName, osName, osVersion } from 'react-device-detect';
import SessionProvider from './SessionProvider';

const session = SessionProvider.get();
let headers = {};
let user = {};

if (session.isValid) {
  user = session.payload.user;
  headers = {
    'Authorization': `Bearer ${session.payload.token}`,
    'Content-Type': 'application/json',
  };
}

class CaseProvider {
  static async add(data) {
    data.metadata = { user };
    data.metadata.device = {
      browser: browserName,
      os_name: osName,
      os_version: osVersion,
    };
    return await axios({
      mode: 'no-cors',
      method: 'POST',
      url: `${config.api.baseurl}cases`,
      crossdomain: true,
      headers,
      data,
    })
    .then((json) => json.data)
    .then((res) => {
      return res;
    });
  }

  static async addBulk(data) {
    data.metadata = { user };
    data.metadata.device = {
      browser: browserName,
      os_name: osName,
      os_version: osVersion,
    };
    return await axios({
      mode: 'no-cors',
      method: 'POST',
      url: `${config.api.baseurl}cases/bulk`,
      crossdomain: true,
      headers,
      data,
    })
    .then((json) => json.data)
    .then((res) => {
      return res;
    });
  }

  static async getTypes() {
    return await axios({
      mode: 'no-cors',
      method: 'GET',
      url: `${config.api.baseurl}cases/types`,
      crossdomain: true,
      headers,
    })
    .then((json) => json.data)
    .then((res) => {
      return res.data;
    });
  }
}

export default CaseProvider;
