export default {
    stage: process.ENV ? process.ENV : 'dev',
    project: {
        id: '2d3afb76-d17e-4042-8b06-8afe0e1138a4',
        company: 'e61d9d19-607c-4b50-bacf-37a3416083b3',
    },
    api: {
        // baseurl: 'http://localhost:8080/',
        baseurl: 'https://sahealthdata-api.herokuapp.com/',
    },
    geoip: {
        url: 'https://geolocation-db.com/json',
    },
    GOOGLE_API_KEY: 'AIzaSyCICoEnxe2TyqO1-UjM090GR0RE4AGq08E',
    GOOGLE_SHEETS: {
        apicredentials: {
            type: 'service_account',
            project_id: 'track-and-trace-309307',
            private_key_id: '6bcd14cac310eca242f65ed601c72d26620bb622',
            private_key: '-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDCMlhFc2H5ZLuZ\nfL2Mg7/kxvPBpL3nmK/ss+RR0IccXMziInLaHW4O1a0ded0pOLbTFkiWV3ngofLa\nDj2SFtNI4ZfhnsTY0wgzJMSi2wuAzb0k7AMgpjMy86PSeVYIY2EftA8SMux7fl+g\nYHBAO8/NLBg8pS+6VKLxBa9wGlnIeQUGVGIgu5lYk6DytqUggZFR9y/WlBGD1XgW\nwF3J+5hICGbDFwdHbaFpR1weRTyEs28JE7R6F+2pLSrMVZPXltSHEjB76PtZdsK0\nsdhG6MdF4mD6vX8der20VLDCxs2hggGY21iNJ/40kq3Z/6cme4QfDSSORFOg9OEQ\nydJynGrLAgMBAAECggEAQfQ7SosfcUYm+MAG2Fw+pgJz8wZygN+4kBmgp7C80oMq\nCntMqotoP52Oh/5FgKSEYzikOntHJ2WZRzmi66TY6V3E6Zm0nTc/B6+rIOxoAbze\nSlXl0gbgSKO6tdcDwyGNcOkEXVfyPRb0f1GBji9CKhqZuK8ZgJ0SIe2eYeJxL8Zi\noM6cbVAy/JzvQzIxedI2Ctsdsu2w6iSfj8yZf2u/KpYEjs1k3oT1MLRQ3HkAt2Vu\n+40ZxqKUsFyy6hzUWWB1oZzqoOmi1NvFB8nhvZdAka7d3Gry8RNrbroDjsj8wxXW\n8IT7q3MFr5XMxYyOaxuEGcMA7NYIzBCyhGKYmvN9MQKBgQDrsr6i0/H9OWAfbVhS\ngqEjUB7Jzs3LWQMxB58joY+SK5bhUJcU/4lF63DtvLhqamxl7I/dqFlIXN3HrBrl\n1xJeSd6U9oRk09PpJ1FyNA6A8csvb1APflSnAaV9tdU02ijSSbuOSpOn9hfigiUj\n3mtweNIY0lyEt7aK1FHqZqP0XwKBgQDS7HiuKeKEw7gkePP+yzI08pS9jl8zGYdK\nkt2s8UUBC+/LXemmGJOwd+iV5B3RVsYH1bvh0Vl8hqcVNJzfwUvFXlHOwCmWm7wt\nZzRC7mGrF/2HCCnKqsi8nd+Am3yhxaOlJi4G2Ew7h9ePqHDQh4F3rK6JK8foSEBm\ngoXJ8OsBFQKBgQDZh3VkB+Itx/dL6fn65K7dTYOs012hkDxe0t7v3psH14z/1AYs\nDg9eZYYrE+LL6/6xLtw0QbxtCbByKQgGGjHMrbZsmvvjppDMfUow8ZR1MzNISx6D\nvh9Ypy9aTyhACBxmwEAGehO+j2TJ2z111kAoZIna+MMEhh1GEeCsSiCFVwKBgCB/\nAETM/TI5OTidTfI2eiDAMvxVYANubnKl1/NaJKh5n1M0v9ewN6jbgID/aTykYtQ4\nML8k0uWllT5mIbkzfaoF0xLIdl/W1JImMV4SfgevJYdVUaMA1vaAWDvnOLdvzLuX\n+0Vgs7YEQsjGScNG7UJz31wQ27bE0u84MsafEIXVAoGAZqZxbQcm8WayskerZm0G\noWtdrJO9DW41IN7Yklxjjwp8rswxHjRDnD9E9RDgFe7dKxHlXqftwCSVsY9+3UEw\n21I4u39wiKc2/X+3XaYu0Y4xN4Exar7foT3cvaiPZNU1gqyNi7f2jLLDHfsSbj23\nObF/kyDgumW5mu1ILMOfi1g=\n-----END PRIVATE KEY-----\n',
            client_email: 'tacos-track-order-service@track-and-trace-309307.iam.gserviceaccount.com',
            client_id: '103543113175909071046',
            auth_uri: 'https://accounts.google.com/o/oauth2/auth',
            token_uri: 'https://oauth2.googleapis.com/token',
            auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs',
            client_x509_cert_url: 'https://www.googleapis.com/robot/v1/metadata/x509/tacos-track-order-service%40track-and-trace-309307.iam.gserviceaccount.com',
        },
        healthdata: {
            id: '1-4wUKt6qccv-BNWQXL3YY-VANaELdeLn5pwlPY-n9Qg',
            sheets: {
                cases: { id: '34705520', title: 'Cases' },
                countries: { id: '0', title: 'Countries' },
                provinces: { id: '1900091629', title: 'Provinces' },
                resources: { id: '1624834888', title: 'Resources' }
            }
        },
    },
};
