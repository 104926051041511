import React, { useState } from 'react';
// import { Session } from 'bc-react-session';
import { HashLinkContainer } from 'components';
import { SessionProvider } from 'providers';

const session = SessionProvider.get();

function Navigation(props) {
	const { country } = props;
	const [ menuExpanded, setMenuExpanded ] = useState(false);
	const isProjects = window.location.pathname.indexOf('/projections') !== -1;
	const flag = require(`images/flags/russia_flag.jpg`);

	const handleProfileMenuToggle = (e) => {
		e.preventDefault();
		setMenuExpanded(!menuExpanded);
	};

	return (
		<React.Fragment>
			{(session.isValid && !isProjects)
				&& (
				<header className="navbar navbar-header navbar-header-fixed">
					<a href="/cpanel/dashboard" id="mainMenuOpen" className="burger-menu">
						<i data-feather="menu"></i>
					</a>
					<div className="navbar-brand">
						<a href="/cpanel/dashboard" className="df-logo">
							sahealth<span>data</span>cpanel
						</a>
					</div>
					<div className="navbar-right">
						<div className="dropdown dropdown-profile">
							<a
								href="/cpanel/dashboard"
								role="button"
								className="dropdown-link"
								data-toggle="dropdown"
								data-display="static"
								aria-expanded={menuExpanded}
								onClick={handleProfileMenuToggle}
							>
								{session.payload.user.first_name}
								&nbsp;
								{session.payload.user.last_name}
								&nbsp;&nbsp;
								<div className="avatar avatar-sm">
									<img src={flag} className="rounded-circle" alt={session.payload.user.first_name} />
								</div>
								&nbsp;&nbsp;
								<i className="icon ion-ios-arrow-down"></i>
							</a>
							<div className={`dropdown-menu dropdown-menu-right ${menuExpanded ? 'show' : ''} tx-13`}>
								<h6 className="tx-semibold mg-b-5">
									{session.payload.user.first_name}
									&nbsp;
									{session.payload.user.last_name}
								</h6>
								<p className="mg-b-20 tx-12 tx-color-03">
									{session.payload.user.user_role.description}
								</p>

								{/* <HashLinkContainer to="/cpanel/account/profile">
									<button className="dropdown-item"><i data-feather="edit-3"></i> View Profile</button>
								</HashLinkContainer>
								<HashLinkContainer to="/cpanel/auth/profile">
									<button className="dropdown-item"><i data-feather="settings"></i>Account Settings</button>
								</HashLinkContainer> */}
								<div className="dropdown-divider"></div>
								<a href="/cpanel/login" className="dropdown-item"><i data-feather="log-out"></i>Sign Out</a>
							</div>
						</div>
					</div>
				</header>)}
			{(!session.isValid || isProjects)
				&& (
				<header className="navbar navbar-header navbar-header-fixed">
					<div className="navbar-brand">
						<HashLinkContainer to="/">
							<a href="/" className="df-logo">sahealth<span>data</span></a>
						</HashLinkContainer>
					</div>
					<div className="navbar-right">
						<div className="dropdown dropdown-profile">
							<HashLinkContainer to="/">
								<a
									href="/"
									className="dropdown-link"
									data-toggle="dropdown"
									data-display="static"
									aria-expanded={menuExpanded}
									onClick={handleProfileMenuToggle}
								>
									Covid-19 Statistics&nbsp;&nbsp;
									<div className="avatar avatar-sm">
										<img src={flag} className="rounded-circle" alt={country.name} />
									</div>
									&nbsp;&nbsp;
									<i className="icon ion-ios-arrow-down"></i>
								</a>
							</HashLinkContainer>
							<div className={`dropdown-menu dropdown-menu-right tx-13 ${menuExpanded ? 'show' : ''}`}>
								<a href="/" className="dropdown-item">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit-3">
										<polygon points="14 2 18 6 7 17 3 17 3 13 14 2"></polygon>
										<line x1="3" y1="22" x2="21" y2="22"></line>
									</svg>
									&nbsp;Covid-19 Statistics
								</a>
								<div className="dropdown-divider"></div>
								<a href="/" className="dropdown-item">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-help-circle">
										<circle cx="12" cy="12" r="10"></circle>
										<path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
										<line x1="12" y1="17" x2="12" y2="17"></line>
									</svg>
									&nbsp;Help Center
								</a>
							</div>
						</div>
					</div>
				</header>)}
		</React.Fragment>
	);
}

export default Navigation;
