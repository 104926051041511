import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import {
	Card,
	CardBody,
	Container,
	Col,
	Row,
} from 'reactstrap';
import {
	Loading,
	// CaseStats,
	// DeathsPerDayStats,
	ResourceUseStats,
	ResourceCountStats,
	TotalDeathStats,
	InfectionsVsRecoveries,
	InfectedPersons,
	InfectionsByGender,
	InfectionsQuarantineByRegion,
	InfectionsRecoveredDeathsByAge,
	NumberOfTests,
	OriginOfInfectionPie,
	TestingSites,
} from 'components';
import { CountriesProvider, GoogleSheetsProvider, StatsProvider } from 'providers';
import moment from 'moment';

const Projections = () => {
	const [provinces, setProvinces] = useState([]);
	const [statSumm, setStatSumm] = useState({});
	const [province, setProvince] = useState('all');
	const [view, setView] = useState('provinces');
	const [loaded, setLoaded] = useState(false);
	const [query, setQuery] = useState('');

	document.title = 'Coronavirus Statistics - South Africa';

	useEffect(() => {
		const fetchData = async () => {
			let query = '';
			const cases = await GoogleSheetsProvider.cases();
			const resources = await GoogleSheetsProvider.resources();
			if (window.location.search) {
				const obj = queryString.parse(window.location.search);
				if (obj.p) {
					const province = provinces.filter(item => item.name === obj.p);
					setProvince(province[0].name);
					query = `province_id=${province[0].id}`;
					setQuery(query);
					setView('districts');
				}
			}
			// const statSumm = await StatsProvider.get('resources', 'all', query);
			// const caseSumm = await StatsProvider.get('cases', 'summary', query);
			// const cases = caseSumm[0];

			const summ = {
				all_bed_tot: 0,
				all_bed_avail: 0,
				all_bed_short: 0,
				icu_bed_tot: 0,
				icu_bed_avail: 0,
				icu_bed_short: 0,
				mobi_lab_tot: 0,
				mobi_lab_avail: 0,
				mobi_lab_short: 0,
				test_sites: 0,
				confirmed: 0,
				deaths: 0,
				recovered: 0,
				cumulative_tests: 0,
				critical_icu: 0,
			};
			console.log(cases);
			if (cases && cases.length > 0) {
				const caseLatest      = cases[cases.length - 1];
				summ.confirmed        = caseLatest.confirmed;
				summ.deaths           = caseLatest.deaths;
				summ.recovered        = caseLatest.recovered;
				summ.cumulative_tests = caseLatest.cumulative_tests;
				summ.critical_icu     = caseLatest.critical_icu;
			}
			if (resources && resources.length > 0) {
				const resourceLatest = resources[resources.length - 1];
				summ.all_bed_tot     = resourceLatest.all_bed_tot;
				summ.all_bed_avail   = resourceLatest.all_bed_avail;
				summ.all_bed_short   = resourceLatest.all_bed_short;
				summ.icu_bed_tot     = resourceLatest.icu_bed_tot;
				summ.icu_bed_avail   = resourceLatest.icu_bed_avail;
				summ.icu_bed_short   = resourceLatest.icu_bed_short;
				summ.mobi_lab_tot    = resourceLatest.mobi_lab_tot;
				summ.mobi_lab_avail  = resourceLatest.mobi_lab_avail;
				summ.mobi_lab_short  = resourceLatest.mobi_lab_short;
				summ.test_sites      = resourceLatest.test_sites;
			}
			setStatSumm(summ);
			setLoaded(true);
		};
		fetchData();
	}, []);

	const handleSelectProvince = async (e) => {
		const province = e.currentTarget.value;
		const query = province === 'all' ? '' : `?p=${province}`;
		window.location = `/${query}`;
	};

	return (
		<Container fluid className="pd-x-0 pd-lg-x-10 pd-xl-x-0">
			{!loaded && <Loading text="Loading data please wait" />}
			<div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-30">
				<div>
					<nav aria-label="breadcrumb">
						<ol className="breadcrumb breadcrumb-style1 mg-b-10">
							<li className="breadcrumb-item">
								<a href="/">Statistics</a>
							</li>
							<li className="breadcrumb-item active" aria-current="page">South Africa</li>
						</ol>
					</nav>
					<h4 className="mg-b-0 tx-spacing--1">
						COVID-19 Statistics
						<span className="tx-color-03">{province !== 'all' ? ` for ${province}` : ''}</span>
					</h4>
				</div>
				{/* <div className="d-none d-md-block">
					<div data-label="Example" className="df-example demo-forms">
						<div>
							<select
								className="form-control select2"
								onChange={handleSelectProvince}
								value={province}
							>
								<option label="All Provinces (South Africa)" value="all">All Provinces</option>
								{provinces.length > 0
								&& provinces.map(province => {
									if (province.iso_code !== 'UA')
										return (<option key={province.id} label={province.name} value={province.name}>{province.name}</option>)
									return null;
									
								})}
							</select>
						</div>
					</div>
				</div> */}
			</div>
			<Row className="row-xs">
				<Col lg={12} className="mg-b-10">
					<Card className="mg-b-10">
						<CardBody>
							<div className="media">
								<span className="tx-color-04">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-pie-chart wd-60 ht-60">
									<path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
									<path d="M22 12A10 10 0 0 0 12 2v10z"></path>
								</svg>
								</span>
								<div className="media-body mg-l-20">
									<h6 className="mg-b-10">The charts below show projected hospital resource use based on COVID-19 stats. The model assumes continued social distancing until the end of {moment().format('MMMM YYYY')}.</h6>
									<p className="tx-color-03 mg-b-0">
										In locations without social distancing measures currently in place, we have assumed they will be in place within seven days of the last model update. If not, the number of deaths and burden on their hospital systems will likely be higher than the model predicts.
										For answers to other frequently asked questions about the modeling.
										To view the paper with in-depth analysis and a description of the methods used to produce the projections.
									</p>
								</div>
							</div>
						</CardBody>
					</Card>
				</Col>
				<Col sm={6} lg={3}>
					{loaded
					&& (<ResourceCountStats
						heading="All Beds"
						value={statSumm.all_bed_avail ? statSumm.all_bed_avail : 0}
						// summary="Estimated daily unique views per visitor on the ads."
						summary="A number of all hospital beds available."
						txColor="primary"
					/>)}
				</Col>
				<Col sm={6} lg={3} className="mg-t-10 mg-sm-t-0">
					{loaded
					&& (<ResourceCountStats
						heading="Bed Shortages"
						value={statSumm.all_bed_short ? statSumm.all_bed_short : 0}
						summary="Estimated number of hospital bed shortages."
						txColor="pink"
					/>)}
				</Col>
				<Col sm={6} lg={3} className="mg-t-10 mg-sm-t-0">
					{loaded
					&& (<ResourceCountStats
						heading="ICU Beds"
						value={statSumm.icu_bed_avail ? statSumm.icu_bed_avail : 0}
						summary="Total number of available hospital ICU beds."
						txColor="orange"
					/>)}
				</Col>
				<Col sm={6} lg={3} className="mg-t-10 mg-sm-t-0">
					{loaded
					&& (<ResourceCountStats
						heading="Testing Sites"
						value={statSumm.test_sites ? statSumm.test_sites : 0}
						summary="Total number of available testing sites."
						txColor="teal"
					/>)}
				</Col>
				<Col lg={12} className="mg-t-10">
					<ResourceUseStats
						{...statSumm}
						view={view}
						loaded={loaded}
						query={query}
						province={province}
					/>
				</Col>
				{/* {view === 'provinces'
				&& (
				<Col lg={12} className="mg-t-10">
					<Row>
						<Col xs={6} lg={3}>
							<Card className="card-body">
								<h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
									Confirmed Cases
								</h6>
								<div className="d-flex d-lg-block d-xl-flex align-items-end">
									<h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">
										<NumberFormat displayType={'text'} thousandSeparator value={statSumm.confirmed ? statSumm.confirmed : 0} />
									</h3>
									<p className="tx-11 tx-color-03 mg-b-0">
										<span className="tx-medium tx-success">1.2% <i className="icon ion-md-arrow-up"></i></span> than yesterday
									</p>
								</div>
							</Card>
						</Col>
						<Col xs={6} lg={3}>
							<Card className="card-body">
								<h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
									In Isolation
								</h6>
								<div className="d-flex d-lg-block d-xl-flex align-items-end">
									<h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">
										<NumberFormat displayType={'text'} thousandSeparator value={0} />
									</h3>
									<p className="tx-11 tx-color-03 mg-b-0">
										<span className="tx-medium tx-success">1.2% <i className="icon ion-md-arrow-up"></i></span> than yesterday
									</p>
								</div>
							</Card>
						</Col>
						<Col xs={6} lg={3}>
							<Card className="card-body">
								<h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
									Hospitalised
								</h6>
								<div className="d-flex d-lg-block d-xl-flex align-items-end">
									<h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">
										<NumberFormat displayType={'text'} thousandSeparator value={0} />
									</h3>
									<p className="tx-11 tx-color-03 mg-b-0">
										<span className="tx-medium tx-danger">0.7% <i className="icon ion-md-arrow-down"></i></span> than yesterday
									</p>
								</div>
							</Card>
						</Col>
						<Col xs={6} lg={3}>
							<Card className="card-body">
								<h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
									Intensive Care
								</h6>
								<div className="d-flex d-lg-block d-xl-flex align-items-end">
									<h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">
										<NumberFormat displayType={'text'} thousandSeparator value={statSumm.critical_icu ? statSumm.critical_icu : 0} />
									</h3>
									<p className="tx-11 tx-color-03 mg-b-0">
										<span className="tx-medium tx-success">1.2% <i className="icon ion-md-arrow-up"></i></span> than yesterday
									</p>
								</div>
							</Card>
						</Col>
						<Col xs={6} lg={3}>
							<Card className="card-body mg-t-20">
								<h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
									Recovered
								</h6>
								<div className="d-flex d-lg-block d-xl-flex align-items-end">
									<h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">
										<NumberFormat displayType={'text'} thousandSeparator value={statSumm.recovered ? statSumm.recovered : 0} />
									</h3>
									<p className="tx-11 tx-color-03 mg-b-0">
										<span className="tx-medium tx-success">1.2% <i className="icon ion-md-arrow-up"></i></span> than yesterday
									</p>
								</div>
							</Card>
						</Col>
						<Col xs={6} lg={3}>
							<Card className="card-body mg-t-20">
								<h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
									In Quarantine
								</h6>
								<div className="d-flex d-lg-block d-xl-flex align-items-end">
									<h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">
										<NumberFormat displayType={'text'} thousandSeparator value={0} />
									</h3>
									<p className="tx-11 tx-color-03 mg-b-0">
										<span className="tx-medium tx-success">1.2% <i className="icon ion-md-arrow-up"></i></span> than yesterday
									</p>
								</div>
							</Card>
						</Col>
						<Col xs={6} lg={3}>
							<Card className="card-body mg-t-20">
								<h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
									Quarantine Completed
								</h6>
								<div className="d-flex d-lg-block d-xl-flex align-items-end">
									<h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">
										<NumberFormat displayType={'text'} thousandSeparator value={0} />
									</h3>
									<p className="tx-11 tx-color-03 mg-b-0">
										<span className="tx-medium tx-success">1.2% <i className="icon ion-md-arrow-up"></i></span> than yesterday
									</p>
								</div>
							</Card>
						</Col>
						<Col xs={6} lg={3}>
							<Card className="card-body mg-t-20">
								<h6 className="tx-uppercase tx-11 tx-spacing-1 tx-color-02 tx-semibold mg-b-8">
									Samples
								</h6>
								<div className="d-flex d-lg-block d-xl-flex align-items-end">
									<h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">
										<NumberFormat displayType={'text'} thousandSeparator value={0} />
									</h3>
									<p className="tx-11 tx-color-03 mg-b-0">
										<span className="tx-medium tx-success">1.2% <i className="icon ion-md-arrow-up"></i></span> than yesterday
									</p>
								</div>
							</Card>
						</Col>
					</Row>
				</Col>)} */}
				{view === 'provinces'
				&& (
				<Col lg={12} className="mg-t-20">
					<InfectionsVsRecoveries
						view={view}
						loaded={loaded}
						query={query}
						province={province}
					/>
				</Col>)}
				{view === 'provinces'
				&& (
				<Col lg={12} xl={8} className="mg-t-20">
					<InfectedPersons
						view={view}
						loaded={loaded}
						query={query}
						province={province}
					/>
				</Col>)}
				{view === 'provinces'
				&& (
				<Col lg={12} xl={4} className="mg-t-20">
					<Row>
						<Col lg={6} xl={12} className="mg-b-10">
							<OriginOfInfectionPie />
						</Col>
						<Col lg={6} xl={12}>
							<InfectionsByGender />
						</Col>
					</Row>
				</Col>)}
				{view === 'provinces'
				&& (
				<Col lg={12} className="mg-t-20">
					<NumberOfTests
						view={view}
						loaded={loaded}
						query={query}
						province={province}
					/>
				</Col>)}
				{view === 'provinces'
				&& (
				<Col lg={12} className="mg-t-10">
					<TestingSites
						view={view}
						loaded={loaded}
						query={query}
						province={province}
					/>
				</Col>)}
				{view === 'provinces'
				&& (
				<Col md={6} xl={6} className="mg-t-10">
					<InfectionsQuarantineByRegion />
				</Col>)}
				{view === 'provinces'
				&& (
				<Col md={6} xl={6} className="mg-t-10">
					<InfectionsRecoveredDeathsByAge />
				</Col>)}
				{/* {view === 'provinces'
				&& (
				<Col lg={12} className="mg-t-20">
					<DeathsPerDayStats
						view={view}
						loaded={loaded}
						query={query}
						province={province}
					/>
				</Col>)} */}
				{view === 'provinces'
				&& (
				<Col lg={12} className="mg-t-10">
					<TotalDeathStats
						view={view}
						loaded={loaded}
						query={query}
						province={province}
					/>
				</Col>)}
				{/* {view === 'provinces'
				&& (
				<Col lg={12} className="mg-t-10">
					<CaseStats
						view={view}
						loaded={loaded}
						query={query}
						province={province}
					/>
				</Col>)} */}
			</Row>
		</Container>
	);
}

export default Projections;
