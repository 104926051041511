import moment from 'moment';
import { GoogleSpreadsheet } from 'google-spreadsheet';
import config from '../config';

const { GOOGLE_SHEETS } = config;

class GoogleSheetsProvider {
    static async countries() {

        // Initialize the sheet - doc ID is the long id in the sheets URL
        const doc = new GoogleSpreadsheet(GOOGLE_SHEETS.healthdata.id);

        // Initialize Auth
        await doc.useServiceAccountAuth(GOOGLE_SHEETS.apicredentials);
        
        // load document properties and worksheets
        await doc.loadInfo();

        const sheet = doc.sheetsByTitle[GOOGLE_SHEETS.healthdata.sheets.countries.title];
        if (sheet.title) {
            // read rows
            const rows = await sheet.getRows();
            return rows;
        }
        return [];
    }

    static async provinces() {

        // Initialize the sheet - doc ID is the long id in the sheets URL
        const doc = new GoogleSpreadsheet(GOOGLE_SHEETS.healthdata.id);

        // Initialize Auth
        await doc.useServiceAccountAuth(GOOGLE_SHEETS.apicredentials);
        
        // load document properties and worksheets
        await doc.loadInfo();

        const sheet = doc.sheetsByTitle[GOOGLE_SHEETS.healthdata.sheets.provinces.title];
        if (sheet.title) {
            // read rows
            const rows = await sheet.getRows();
            return rows;
        }
        return [];
    }

    static async resources() {

        // Initialize the sheet - doc ID is the long id in the sheets URL
        const doc = new GoogleSpreadsheet(GOOGLE_SHEETS.healthdata.id);

        // Initialize Auth
        await doc.useServiceAccountAuth(GOOGLE_SHEETS.apicredentials);
        
        // load document properties and worksheets
        await doc.loadInfo();

        const sheet = doc.sheetsByTitle[GOOGLE_SHEETS.healthdata.sheets.resources.title];
        if (sheet.title) {
            // read rows
            const rows = await sheet.getRows();
            return rows;
        }
        return [];
    }

    static async cases() {

        // Initialize the sheet - doc ID is the long id in the sheets URL
        const doc = new GoogleSpreadsheet(GOOGLE_SHEETS.healthdata.id);

        // Initialize Auth
        await doc.useServiceAccountAuth(GOOGLE_SHEETS.apicredentials);
        
        // load document properties and worksheets
        await doc.loadInfo();

        const sheet = doc.sheetsByTitle[GOOGLE_SHEETS.healthdata.sheets.cases.title];
        if (sheet.title) {
            // read rows
            const rows = await sheet.getRows();
            return rows;
        }
        return [];
    }

    static async sheet(type) {

        // Initialize the sheet - doc ID is the long id in the sheets URL
        const doc = new GoogleSpreadsheet(GOOGLE_SHEETS.healthdata.id);

        // Initialize Auth
        await doc.useServiceAccountAuth(GOOGLE_SHEETS.apicredentials);
        
        // load document properties and worksheets
        await doc.loadInfo();

        const sheet = doc.sheetsByTitle[GOOGLE_SHEETS.healthdata.sheets[type].title];
        if (sheet.title) {
            // read rows
            const rows = await sheet.getRows();
            return rows;
        }
        return [];
    }

    static async updateLocation(longitude, latitude, code) {

        // Initialize the sheet - doc ID is the long id in the sheets URL
        const doc = new GoogleSpreadsheet(GOOGLE_SHEETS.orders.id);

        // Initialize Auth
        await doc.useServiceAccountAuth(GOOGLE_SHEETS.apicredentials);
        
        // load document properties and worksheets
        await doc.loadInfo();

        const sheet = doc.sheetsByTitle[moment().format('YYYY-MM-DD')];
        if (sheet.title) {
            // read rows
            const rows = await sheet.getRows();

            // loop through rows and update
            if (rows && rows.length > 0) {
                rows.map(async (item, index) => {
                    if (item['Delivered By'] === code) {
                        if (item['Longitude'] !== longitude || item['Latitude'] !== latitude) {
                            rows[index]['Longitude'] = longitude;
                            rows[index]['Latitude'] = latitude;
                            await rows[index].save();
                        }
                    }
                });
                await sheet.saveUpdatedCells();
                return true;
            }
        }
        return false;
    }
}

export default GoogleSheetsProvider;
